import { useState, useEffect } from 'react';
import Spinner from '../spinner/Spinner';
import useMarvelService from "../../services/MarvelService";
import ErrorMessage from "../errorMessage/ErrorMessage";

import './randomChar.scss';
import mjolnir from '../../resources/img/mjolnir.png';

const RandomChar = props => {
	const [char, setChar] = useState({});

	const { loading, error, getCharacter, clearError } = useMarvelService();

	useEffect(() => {
		updateChar();
	}, [])


	const onCharLoaded = (char) => {
		setChar(char);
	}

	const updateChar = () => {
		clearError();
		const id = Math.floor(Math.random() * (1011400 - 1011000) + 1011000);

		getCharacter(id)
			.then(onCharLoaded);
	}

	const errorMessage = error && !loading ? <ErrorMessage /> : null;
	const spinner = loading ? <Spinner /> : null;
	const content = !(loading || error) ? <View char={char} /> : null;

	return (
		<div className="randomchar" >
			{spinner}
			{errorMessage}
			{content}
			<div className="randomchar__static">
				<p className="randomchar__title">
					Random character for today!<br />
					Do you want to get to know him better?
				</p>
				<p className="randomchar__title">
					Or choose another one
				</p>
				<button
					onClick={updateChar}
					className="button button__main"
					disabled={loading}>
					<div className="inner">try it</div>
				</button>
				<img src={mjolnir} alt="mjolnir" className="randomchar__decoration" />
			</div>
		</div>
	)
}

const View = ({ char }) => {
	const { name, descrition, thumbnail, homepage, wiki } = char;
	let imgStyle = { 'objectFit': 'cover' };
	if (thumbnail === 'http://i.annihil.us/u/prod/marvel/i/mg/b/40/image_not_available.jpg') {
		imgStyle = { 'objectFit': 'contain' };
	}

	return (
		<div className="randomchar__block">
			<img src={thumbnail} alt="Random character" className="randomchar__img" style={imgStyle} />
			<div className="randomchar__info">
				<p className="randomchar__name">{name}</p>
				<p className="randomchar__descr">{descrition}</p>
				<div className="randomchar__btns">
					<a href={homepage} className="button button__main" target="_blank" rel="noreferrer">
						<div className="inner">homepage</div>
					</a>
					<a href={wiki} className="button button__secondary" target="_blank" rel="noreferrer">
						<div className="inner">Wiki</div>
					</a>
				</div>
			</div>
		</div>
	)
}

export default RandomChar;